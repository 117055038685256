<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";

import Swal from "sweetalert2";
import axios from "axios";
import $ from "jquery";
/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Tambah Sponsorship",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Tambah Sponsorship",
      items: [
        {
          text: "Monitoring",
          href: "/",
        },
        {
          text: "Sponsorship",
          href: "/sponsorship",
        },
        {
          text: "Tambah",
          active: true,
        },
      ],
      // Catch Error Axios
      axiosCatchError: null,
      row_dokumen_pertanggung_jawaban: [],
      // variable Page
      master_keuntungan: [],
      master_klasifikasi: [],
      master_lingkup: [],
      master_user: [],
      master_user_selected: [],
      master_keuntungan_selected: [],
      master_klasifikasi_selected: "",
      master_lingkup_selected: "",

      // variable save
      nama_acara: "",
      tgl_acara: new Date().toISOString().slice(0, 10),
      tanggal_sekarang: new Date().toISOString().slice(0, 10),
      gambaran_umum_acara: "",
      permohonan_nilai_sponsor: "",
      peserta_selected: "",
      jumlah_peserta_selected: "",
      lokasi_kegiatan: "",
      range_usia_selected: "",
      gender_selected: "",
      konsep_acara: [],
      lembaga_pengaju: "",
      unit_kerja: [],
      lingkup_kerjasama: "",
      logo: [],
      ukuran_logo_selected: "",
      jumlah_logo_selected: "",
      benefit: "",
      pertimbangan_sponsor: "",
      tanggal_realiasi: "",
      nilai_sponsor: "",
      total_realiasi: "",
      sisa_anggaran: "",
      is_pajak: "",
      termin_selected: "",
      informasi_pembayaran: "",
      sharing_dana: "",
      is_terlaksana: "",
      catatan_kegiatan: "",
      pic_terlaksana: "",
      kontak_terlaksana: "",
      alamat_terlaksana: "",

      showInformasiTambahan: false,
      showKantorIndividu: false,
      showKantorSharing: false,
      showBenefit: false,
      showRealisasiPembayaran: false,
      informasi_tambahan: [],
      master_kantor: [],
      kantor_sharing_selected: [],
      kantor_individu_selected: "",
      realisasi_pembayaran: [],
      sharing_dana_array: [],
      showSharingDana: false,

      //upload konfigurasi
      max_upload_size: 0,
      label_upload_tipe: "*",
      textlabel_upload_tipe: "all",
    };
  },
  mounted() {
    this.getKlasifikasi();
    this.getKeuntungan();
    this.getLingkup();
    this.getUser();
    this.getKantor();
    this.getKonfigurasiEkstensi();
  },
  methods: {
    getKonfigurasiEkstensi() {
      let self = this;
      axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "/api/master/konfigurasi_ekstensi?menu=monitoring"
        )
        .then((response) => {
          var response_data = response.data;
          if (response_data.code == 200) {
            var data_ekstensi = response_data.list_data.data[0];
            var ekstensi = JSON.parse(data_ekstensi.value_extension);
            self.label_upload_tipe = ekstensi
              .map((value) => value.label)
              .join(",");
            self.textlabel_upload_tipe = ekstensi
              .map((value) => value.value)
              .join(",");
            self.max_upload_size = data_ekstensi.max_upload_size;
          }
        });
    },
    getKlasifikasi() {
      let self = this;
      self.loadingTable = true;
      axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "/api/master/klasifikasi?status=ENABLE"
        )
        .then((response) => {
          var response_data = response.data;
          if (response_data.code == 200) {
            self.master_klasifikasi = response_data.list_data.data;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data.message,
            });
          }
        });
    },
    getLingkup() {
      let self = this;
      self.loadingTable = true;
      axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "/api/master/lingkup?status=ENABLE"
        )
        .then((response) => {
          var response_data = response.data;
          if (response_data.code == 200) {
            self.master_lingkup = response_data.list_data.data;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data.message,
            });
          }
        });
    },
    getKeuntungan() {
      let self = this;
      self.loadingTable = true;
      axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "/api/master/keuntungan?status=ENABLE"
        )
        .then((response) => {
          var response_data = response.data;
          if (response_data.code == 200) {
            self.master_keuntungan = response_data.list_data.data;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data.message,
            });
          }
        });
    },
    getUser() {
      let self = this;
      self.loadingTable = true;
      axios
        .get(process.env.VUE_APP_BACKEND_URL_VERSION + "/api/master/user/pic")
        .then((response) => {
          var response_data = response.data;
          if (response_data.code == 200) {
            self.master_user = response_data.list_data.data;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data.message,
            });
          }
        });
    },
    addRowDokumenPertanggungJawaban() {
      this.row_dokumen_pertanggung_jawaban.push({
        dokumen_pertanggung_jawaban_id: null,
        nama_dokumen: null,
        dir: null,
      });
    },

    removeRowDokumenPertanggungJawaban(key_deleted) {
      this.row_dokumen_pertanggung_jawaban.splice(key_deleted, 1);
    },

    uploadFileDokumenPJ(row, name) {
      let self = this;
      if ($("#uploadFileDokumenPJ" + row + "")[0].files[0]) {
        if (
          $("#uploadFileDokumenPJ" + row + "")[0].files[0].size <
          self.max_upload_size
        ) {
          $("#uploadLoadingDokumenPJ").html(
            '<span class="badge bg-warning p-1"><i class="fa fa-refresh fa-spin"></i> Loading...</span>'
          );

          var urlres = process.env.VUE_APP_BACKEND_URL_VERSION;
          var FormData = require("form-data");
          var data = new FormData();

          data.append("file", $("#uploadFileDokumenPJ" + row + "")[0].files[0]);
          data.append("dir", "file");
          data.append("nama_dokumen", name);
          data.append("accepted_file", self.label_upload_tipe);

          var config = {
            method: "post",
            url:
              process.env.VUE_APP_BACKEND_URL_VERSION +
              "api/master/uploadhandle/uploadscustom",
            headers: {
              Accept: "application/json",
            },
            data: data,
          };
          axios(config).then(function (response) {
            var dir_name = response.data.dir_name;
            urlres += dir_name;

            $("#uploadLoadingDokumenPJ").html(
              '<span class="badge bg-success p-1"><i class="fa fa-check"></i> Berhasil </span> <a href="' +
              urlres +
              '" target="_blank" style="padding-left:10px;"><span class="badge bg-success p-1"><i class="fa fa-eye"></i> Lihat File</span></a>'
            );
            // self.file_dokumen_pendukung = dir_name;
            self.row_dokumen_pertanggung_jawaban[row].dir = dir_name;
            // $("#valUploadFileDokumenPJ"+row+"").val(dir_name);
          }).catch((e) => {
            Swal.fire({
                icon: "error",
                title: "Gagal upload file",
                text: e.response?.data?.message?.file,
            });
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            html:
              "Ukuran file upload melebihi " +
              this.formatBytes(self.max_upload_size),
          });
        }
      }
    },
    uploadFileDokumenBuktiTransfer(row) {
      let self = this;
      if ($("#uploadFileDokumenBuktiTransfer" + row + "")[0].files[0]) {
        if (
          $("#uploadFileDokumenBuktiTransfer" + row + "")[0].files[0].size <
          self.max_upload_size
        ) {
          $("#uploadLoadingDokumenBuktiTransfer" + row + "").html(
            '<span class="badge bg-warning p-1"><i class="fa fa-refresh fa-spin"></i> Loading...</span>'
          );
          var urlres = process.env.VUE_APP_BACKEND_URL_VERSION;
          var FormData = require("form-data");
          var data = new FormData();

          data.append(
            "file",
            $("#uploadFileDokumenBuktiTransfer" + row + "")[0].files[0]
          );
          data.append("dir", "sponsorship/bukti_transfer");
          data.append("accepted_file", self.label_upload_tipe);

          var config = {
            method: "post",
            url:
              process.env.VUE_APP_BACKEND_URL_VERSION +
              "api/master/uploadhandle/uploadscustom",
            headers: {
              Accept: "application/json",
            },
            data: data,
          };
          axios(config).then(function (response) {
            var dir_name = response.data.dir_name;
            urlres += dir_name;
            $("#uploadLoadingDokumenBuktiTransfer" + row + "").html(
              '<span class="badge bg-success p-1"><i class="fa fa-check"></i> Berhasil </span> <a href="' +
              urlres +
              '" target="_blank" style="padding-left:10px;"><span class="badge bg-success p-1"><i class="fa fa-eye"></i> Lihat File</span></a>'
            );
            // self.file_dokumen_pendukung = dir_name;
            self.realisasi_pembayaran[row].bukti_bayar = dir_name;
            // $("#valUploadFileDokumenPJ"+row+"").val(dir_name);
          }).catch((e) => {
            Swal.fire({
                icon: "error",
                title: "Gagal upload file",
                text: e.response?.data?.message?.file,
            });
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            html:
              "Ukuran file upload melebihi " +
              this.formatBytes(self.max_upload_size),
          });
        }
      }
    },
    formatBytes(bytes, decimals = 2) {
      if (!+bytes) return "0 Bytes";

      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB"];

      const i = Math.floor(Math.log(bytes) / Math.log(k));

      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
    },
    StoreData() {
      let self = this;
      var klasifikasi_id = self.master_klasifikasi_selected?.id;
      if (klasifikasi_id) {
        klasifikasi_id = self.master_klasifikasi_selected?.id;
      } else {
        klasifikasi_id = "";
      }

      var lingkup_id = self.master_lingkup_selected?.id;
      if (lingkup_id) {
        lingkup_id = self.master_lingkup_selected?.id;
      } else {
        lingkup_id = "";
      }
      Swal.fire({
        title: '<i class="fas fa-spinner fa-spin"></i>',
        text: "Loading...",
        showConfirmButton: false,
      });

      if (self.tgl_acara < self.tanggal_sekarang) {
        Swal.fire({
          icon: "warning",
          title: "Peringatan...",
          text: "Tanggal Acara kurang dari hari ini",
        });
      } else {
        let required_form = new Promise(function (resolve, reject) {
          // cek required
          if (self.nama_acara == null || self.nama_acara == "" || self.gambaran_umum_acara == null || self.gambaran_umum_acara == "" || self.lembaga_pengaju == null || self.lembaga_pengaju == ""  || self.nilai_sponsor == null || self.nilai_sponsor == "" || self.permohonan_nilai_sponsor == null || self.permohonan_nilai_sponsor == "" || self.master_user_selected.length == 0) {
            Swal.fire({
              icon: "error",
              title: "Peringatan...",
              text: "Pastikan Formulir Terisi Lengkap",
            });
            reject("stop");
          }
          if(self.permohonan_nilai_sponsor == 0){
            Swal.fire({
              icon: "error",
              title: "Peringatan...",
              text: "Permohonan Nilai Sponsor 0",
            });
            reject("stop");
          }
          resolve("next");
        });
        required_form.then(function (response) {
          if (response == "stop") {
            return false;
          }
          var FormData = require("form-data");
          var data = new FormData();
          data.append("nama_acara", self.nama_acara);
          data.append("tgl_acara", self.tgl_acara);
          data.append("gambaran_umum_acara", self.gambaran_umum_acara);
          data.append("permohonan_nilai_sponsor", self.permohonan_nilai_sponsor);
          data.append("peserta_selected", self.peserta_selected);
          data.append("peserta_detail", JSON.stringify(self.informasi_tambahan));
          data.append(
            "kantor_individu_selected",
            JSON.stringify(self.kantor_individu_selected)
          );
          data.append(
            "kantor_sharing_selected",
            JSON.stringify(self.kantor_sharing_selected)
          );
          data.append("jumlah_peserta_selected", self.jumlah_peserta_selected);
          data.append("lokasi_kegiatan", self.lokasi_kegiatan);
          data.append("range_usia_selected", self.range_usia_selected);
          data.append("gender_selected", self.gender_selected);
          data.append("konsep_acara", self.konsep_acara);
          data.append("klasifikasi_id", klasifikasi_id);
          data.append("lingkup_id", lingkup_id);
          data.append(
            "master_user_selected",
            JSON.stringify(self.master_user_selected)
          );
          data.append("lembaga_pengaju", self.lembaga_pengaju);
          data.append("unit_kerja", self.unit_kerja);
          data.append("lingkup_kerjasama", self.lingkup_kerjasama);
          data.append(
            "master_keuntungan_selected",
            self.master_keuntungan_selected
          );
          data.append("logo", self.logo);
          data.append("ukuran_logo_selected", self.ukuran_logo_selected);
          data.append("jumlah_logo_selected", self.jumlah_logo_selected);
          data.append("benefit", self.benefit);
          data.append(
            "keterangan_benefit_produk",
            self.informasi_benefit_pemasaran
          );
          data.append("pertimbangan_sponsor", self.pertimbangan_sponsor);
          data.append("tanggal_realiasi", self.tanggal_realiasi);
          data.append("nilai_sponsor", self.nilai_sponsor);
          data.append("total_realiasi", self.total_realiasi);
          data.append("sisa_anggaran", self.sisa_anggaran);
          data.append("is_pajak", self.is_pajak);
          data.append("termin_selected", self.termin_selected);
          data.append(
            "realisasi_pembayaran",
            JSON.stringify(self.realisasi_pembayaran)
          );
          data.append("informasi_pembayaran", self.informasi_pembayaran);
          data.append("sharing_dana", self.sharing_dana);
          data.append("sharing", JSON.stringify(self.sharing_dana_array));
          data.append("is_terlaksana", self.is_terlaksana);
          data.append("catatan_kegiatan", self.catatan_kegiatan);
          data.append("pic_terlaksana", self.pic_terlaksana);
          data.append("kontak_terlaksana", self.kontak_terlaksana);
          data.append("alamat_terlaksana", self.alamat_terlaksana);
          data.append(
            "files",
            JSON.stringify(self.row_dokumen_pertanggung_jawaban)
          );
          var config = {
            method: "post",
            url:
              process.env.VUE_APP_BACKEND_URL_VERSION +
              "/api/monitoring/sponsorship/store",
            data: data,
          };
          axios(config)
            .then(function (response) {
              var response_data = response.data;
              if (response_data.code != 200) {
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  html: response_data.message,
                });
              } else {
                let timerInterval;
                Swal.fire({
                  icon: "success",
                  title: "Berhasil",
                  text: "Anda akan diarahkan ke halaman monitoring sponsorship segera",
                  timer: 2000,
                  timerProgressBar: true,
                  showCancelButton: false,
                  showConfirmButton: false,
                  willClose: () => {
                    clearInterval(timerInterval);
                  },
                }).then((result) => {
                  /* Read more about handling dismissals below */
                  if (result.dismiss === Swal.DismissReason.timer) {
                    self.$router.push({ name: "sponsorship" });
                  }
                });
              }
            })
            .catch((e) => {
              this.axiosCatchError = e.response.data.data;
              Swal.close();
            });
        });

      }
    },
    numberFormat(bilangan) {
      if (parseFloat(bilangan) === 0) {
        Swal.fire({
          icon: "warning",
          title: "Peringatan",
          text: "Input tidak boleh 0 pertama kali",
        });
        return bilangan = null;
      }
      var number_string = bilangan.replace(/[^,\d]/g, "").toString(),
        sisa = number_string.length % 3,
        rupiah = number_string.substr(0, sisa),
        ribuan = number_string.substr(sisa).match(/\d{3}/g);
      if (ribuan) {
        var separator = sisa ? "." : "";
        rupiah += separator + ribuan.join(".");
      }

      return rupiah;
    },

    getKantor() {
      let self = this;
      axios
        .get(
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "/api/master/kantor?status=ENABLE"
        )
        .then((response) => {
          var response_data = response.data;
          if (response_data.code == 200) {
            self.master_kantor = response_data.list_data.data;
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: response_data.message,
            });
          }
        });
    },
    // informasi tambahan
    addinformasitambahan() {
      this.informasi_tambahan.push({
        isi_informasi_tambahan: "",
      });
    },
    removeinformasitambahan(index) {
      this.informasi_tambahan.splice(index, 1);
    },
    // sharing dana
    addsharingdana() {
      this.sharing_dana_array.push({
        kantor: "",
        presentase: "",
        nominal: "",
      });
    },
    removesharingdana(index) {
      this.sharing_dana_array.splice(index, 1);
    },
    pilihPeserta() {
      if (this.peserta_selected == "Tamu atau Undangan") {
        this.showInformasiTambahan = true;
      } else {
        this.showInformasiTambahan = false;
      }
    },
    pilihUnitKerja() {
      if (this.unit_kerja.includes("Individu")) {
        this.showKantorIndividu = true;
      } else {
        this.showKantorIndividu = false;
      }
      if (this.unit_kerja.includes("Sharing")) {
        this.showKantorSharing = true;
      } else {
        this.showKantorSharing = false;
      }
    },
    pilihBenefit() {
      if (this.benefit == "Ya") {
        this.showBenefit = true;
      } else {
        this.showBenefit = false;
      }
    },
    pilihInformasiPembayaran() {
      if (this.informasi_pembayaran == "Sudah Bayar") {
        this.showRealisasiPembayaran = true;
      } else {
        this.showRealisasiPembayaran = false;
      }
    },
    pilihTerminPembayaran() {
      this.realisasi_pembayaran = [];
      const total_termin = this.termin_selected;
      for (let i = 0; i < total_termin; i++) {
        this.realisasi_pembayaran.push({
          tanggal_bayar: "",
          nominal_bayar: "",
          bukti_bayar: "",
        });
      }
    },
    pilihSharingDana() {
      if (this.sharing_dana == "Ya") {
        this.showSharingDana = true;
      } else {
        this.showSharingDana = false;
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <b-form class="p-2" @submit.prevent="StoreData">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12">
                  <b-tabs justified nav-class="nav-tabs-custom" content-class="p-3 text-muted">
                    <!-- Kategori Tab -->
                    <b-tab>
                      <template v-slot:title>
                        <span class="d-inline-block d-sm-none">
                          <i class="fas fa-home"></i>
                        </span>
                        <span class="d-none d-sm-inline-block">Kategori</span>
                      </template>
                      <div class="row p-2">
                        <table class="table mb-0 table-bordered table-condensed table-hover">
                          <thead>
                            <tr class="bg-light">
                              <th colspan="4">Kegiatan</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td style="width: 10%">Nama Acara / Kegiatan <span class="text-danger">* Wajib diisi</span></td>
                              <td style="width: 40%">
                                <b-form-input type="text" v-model="nama_acara"></b-form-input>
                                <small class="text-danger">* Inputkan sesuai nama acara</small>
                              </td>
                              <td style="width: 10%">
                                Tanggal Acara / Kegiatan
                              </td>
                              <td style="width: 40%">
                                <b-form-input type="date" v-model="tgl_acara"></b-form-input>
                              </td>
                            </tr>
                            <tr>
                              <td style="width: 10%">Gambaran Umum Acara <span class="text-danger">* Wajib diisi</span></td>
                              <td colspan="3">
                                <b-form-input type="text" v-model="gambaran_umum_acara"></b-form-input>
                              </td>
                            </tr>
                            <tr>
                              <td style="width: 10%">
                                Permohonan Nilai Sponsor <span class="text-danger">* Wajib diisi</span>
                              </td>
                              <td colspan="3">
                                <input type="text" class="form-control" v-model="permohonan_nilai_sponsor" v-on:input="
                                  permohonan_nilai_sponsor = numberFormat(
                                    permohonan_nilai_sponsor
                                  )" maxlength="15" />
                              </td>
                            </tr>
                            <tr>
                              <td style="width: 10%">Peserta</td>
                              <td colspan="3">
                                <v-select placeholder="-Pilih Peserta-" :options="[
                                  'Umum',
                                  'Mahasiswa',
                                  'Tamu atau Undangan',
                                ]" v-model="peserta_selected" @update:modelValue="pilihPeserta"></v-select>
                                <table v-if="showInformasiTambahan"
                                  class="table mb-0 table-bordered table-condensed table-hover w-full mb-2 mt-2">
                                  <thead>
                                    <tr class="bg-light text-center">
                                      <th>Informasi Tambahan</th>
                                      <th>
                                        <div class="btn btn-success btn-sm" v-on:click="addinformasitambahan()">
                                          <i class="bx bx-plus"></i>
                                        </div>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr v-if="informasi_tambahan.length == 0">
                                      <td colspan="2">TIdak Ada Data</td>
                                    </tr>
                                    <tr v-for="(
                                        item, index
                                      ) in informasi_tambahan" :key="index">
                                      <td>
                                        <input type="text" class="form-control" v-model="item.isi_informasi_tambahan" />
                                      </td>
                                      <td class="text-center">
                                        <button type="button" class="btn btn-danger btn-sm" @click="
                                          removeinformasitambahan(index)
                                          ">
                                          <i class="fa fa-minus"></i>
                                        </button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                            <tr>
                              <td style="width: 10%">Jumlah Peserta</td>
                              <td colspan="3">
                                <v-select placeholder="-Pilih Jumlah Peserta-" :options="[
                                  'Kurang dari 500',
                                  '500 sampai 1000',
                                  'Lebih dari 1000',
                                ]" v-model="jumlah_peserta_selected"></v-select>
                              </td>
                            </tr>
                            <tr>
                              <td style="width: 10%">Lokasi Kegiatan</td>
                              <td colspan="3">
                                <b-form-input type="text" v-model="lokasi_kegiatan"></b-form-input>
                              </td>
                            </tr>
                            <tr>
                              <td style="width: 10%">Range Usia</td>
                              <td colspan="3">
                                <v-select placeholder="-Pilih Range Usia-" :options="[
                                  'Dibawah 20',
                                  '20 - 40',
                                  'Lebih dari 40',
                                ]" v-model="range_usia_selected"></v-select>
                              </td>
                            </tr>
                            <tr>
                              <td style="width: 10%">Gender</td>
                              <td colspan="3">
                                <v-select placeholder="-Pilih Gender-" :options="['Laki - laki', 'Perempuan', 'Mix']"
                                  v-model="gender_selected"></v-select>
                              </td>
                            </tr>
                            <tr>
                              <td style="width: 10%">Konsep Acara</td>
                              <td colspan="3">
                                <input class="form-check-input mt-0" type="checkbox" value="Live/on air"
                                  v-model="konsep_acara" />
                                Live/on air <br />
                                <input class="form-check-input mt-0" type="checkbox" value="Tapping/off air"
                                  v-model="konsep_acara" />
                                Tapping/off air <br />
                                <input class="form-check-input mt-0" type="checkbox" value="Private"
                                  v-model="konsep_acara" />
                                Private <br />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </b-tab>

                    <!-- Kategori Lingkup Tab -->
                    <b-tab>
                      <template v-slot:title>
                        <span class="d-inline-block d-sm-none">
                          <i class="far fa-user"></i>
                        </span>
                        <span class="d-none d-sm-inline-block">Kategori & Lingkup</span>
                      </template>
                      <div class="row p-2">
                        <table class="table mb-0 table-bordered table-condensed table-hover">
                          <thead>
                            <tr class="bg-light">
                              <th colspan="2">Kategori Lingkup</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td style="width: 10%">
                                Klasifikasi Kegiatan
                                <span class="text-danger">* Wajib diisi</span>
                              </td>
                              <td>
                                <v-select placeholder="-Pilih Klasifikasi Kegiatan-" :options="master_klasifikasi"
                                  label="klasifikasi" v-model="master_klasifikasi_selected"></v-select>
                              </td>
                            </tr>
                            <tr>
                              <td style="width: 10%">
                                Lingkup Kegiatan
                                <span class="text-danger">* Wajib diisi</span>
                              </td>
                              <td>
                                <v-select placeholder="-Pilih Lingkup Kegiatan-" :options="master_lingkup" label="lingkup"
                                  v-model="master_lingkup_selected"></v-select>
                              </td>
                            </tr>
                            <tr>
                              <td style="width: 10%">Pic Sponsorship <span class="text-danger">* Wajib diisi</span></td>
                              <td>
                                <v-select v-model="master_user_selected" :options="master_user" label="name"
                                  multiple></v-select>
                              </td>
                            </tr>
                            <tr>
                              <td style="width: 10%">
                                Lembaga/Organisasi Pengajuan Sponsorship <span class="text-danger">* Wajib diisi</span>
                              </td>
                              <td>
                                <b-form-input type="text" v-model="lembaga_pengaju"></b-form-input>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </b-tab>

                    <!-- Pemberi Sponsor Tab -->
                    <b-tab>
                      <template v-slot:title>
                        <span class="d-inline-block d-sm-none">
                          <i class="far fa-envelope"></i>
                        </span>
                        <span class="d-none d-sm-inline-block">Pemberi Sponsor</span>
                      </template>
                      <div class="row p-2">
                        <table class="table mb-0 table-bordered table-condensed table-hover">
                          <thead>
                            <tr class="bg-light">
                              <th colspan="2">Pemberi Sponsor</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td style="width: 10%">
                                Unit Kerja Pengusul Sponsor
                              </td>
                              <td>
                                <input class="form-check-input mt-0" type="checkbox" value="Individu" v-model="unit_kerja"
                                  @change="pilihUnitKerja" />
                                Individu <br />

                                <v-select v-if="showKantorIndividu" placeholder="-Pilih Kantor Individu-"
                                  :options="master_kantor" label="kantor" v-model="kantor_individu_selected"
                                  class="mb-1 mt-1"></v-select>

                                <input class="form-check-input mt-0" type="checkbox" value="Sharing" v-model="unit_kerja"
                                  @change="pilihUnitKerja" />
                                Sharing <br />

                                <v-select v-if="showKantorSharing" placeholder="-Pilih Kantor Sharing-"
                                  :options="master_kantor" label="kantor" v-model="kantor_sharing_selected" multiple
                                  class="mb-1 mt-1"></v-select>
                              </td>
                            </tr>
                            <tr>
                              <td style="width: 10%">
                                Lingkup Kerjasama/Keuntungan
                              </td>
                              <td>
                                <textarea name="lingkup_kerjasama" id="" cols="30" rows="10" class="form-control"
                                  v-model="lingkup_kerjasama"></textarea>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </b-tab>

                    <!-- Keuntungan Tab -->
                    <b-tab>
                      <template v-slot:title>
                        <span class="d-inline-block d-sm-none">
                          <i class="fas fa-cog"></i>
                        </span>
                        <span class="d-none d-sm-inline-block">Keuntungan</span>
                      </template>
                      <div class="row p-2">
                        <table class="table mb-0 table-bordered table-condensed table-hover">
                          <thead>
                            <tr class="bg-light">
                              <th colspan="2">Keuntungan</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td style="width: 10%">Keuntungan</td>
                              <td>
                                <div class="row">
                                  <div class="form-check col-md-3" v-for="(
                                      row_data, key_data
                                    ) in master_keuntungan" :key="key_data">
                                    <input type="checkbox" v-model="master_keuntungan_selected" :value="row_data.id" />
                                    &nbsp;{{ row_data.keuntungan }}
                                  </div>
                                  <div class="form-check col-md-3">
                                    <input type="checkbox" value="Lain-lain" v-model="master_keuntungan_selected" />
                                    Lain-lain &nbsp;
                                    <b-form-input type="text" v-model="master_keuntungan_selected_lainnya
                                      "></b-form-input>
                                    <p class="text-danger">
                                      Jika keuntungan lebih dari 1 pisahkan
                                      menggunakan , (koma)
                                    </p>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td style="width: 10%">
                                Dokumen Laporan Pertanggungjawaban
                              </td>
                              <td>
                                <div class="p-2">
                                  <table class="table table-bordered table-striped">
                                    <thead class="bg-light">
                                      <tr>
                                        <td>Nama Dokumen</td>
                                        <td>
                                          File <br /><small style="color: red">({{ textlabel_upload_tipe }},
                                            Ukuran Maksimal Upload:
                                            {{
                                              formatBytes(max_upload_size)
                                            }})</small>
                                        </td>
                                        <td class="text-end">
                                          <div class="btn btn-success btn-sm" v-on:click="
                                          addRowDokumenPertanggungJawaban()
                                            ">
                                            <i class="bx bx-plus"></i>
                                          </div>
                                        </td>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr v-for="(
                                          row_dokumen_pertanggung_jawaban_value,
                                            row_dokumen_pertanggung_jawaban_key
                                        ) in row_dokumen_pertanggung_jawaban" :key="row_dokumen_pertanggung_jawaban_key
                                          ">
                                        <td>
                                          <b-form-input type="text" v-model="row_dokumen_pertanggung_jawaban_value.nama_dokumen
                                            " placeholder="Nama Dokumen"></b-form-input>
                                        </td>
                                        <td>
                                          <input type="file" :accept="label_upload_tipe" class="form-control" :id="'uploadFileDokumenPJ' +
                                            row_dokumen_pertanggung_jawaban_key
                                            " @change="
    uploadFileDokumenPJ(
      row_dokumen_pertanggung_jawaban_key,
      row_dokumen_pertanggung_jawaban_value.nama_dokumen
    )
    " />
                                          <input type="hidden" v-model="row_dokumen_pertanggung_jawaban_value.dir
                                            " />
                                          <div class="respond-input-file float-left" :id="'uploadLoadingDokumenPJ' +
                                            row_dokumen_pertanggung_jawaban_key
                                            "></div>
                                        </td>
                                        <td>
                                          <div class="btn btn-danger btn-sm" v-on:click="
                                            removeRowDokumenPertanggungJawaban(
                                              row_dokumen_pertanggung_jawaban_key
                                            )
                                            ">
                                            <i class="bx bx-minus"></i>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td style="width: 10%">Logo</td>
                              <td>
                                <div class="row">
                                  <div class="col-md-3">
                                    <input class="form-check-input mt-0" type="checkbox"
                                      value="Sponsor Tunggal / Tittling Name" v-model="logo" />
                                    Sponsor Tunggal / Tittling Name &nbsp;
                                  </div>
                                  <div class="col-md-3">
                                    <input class="form-check-input mt-0" type="checkbox" value="Main Sponsor"
                                      v-model="logo" />
                                    Main Sponsor &nbsp;
                                  </div>
                                  <div class="col-md-3">
                                    <input class="form-check-input mt-0" type="checkbox" value="Sponsor Bersama"
                                      v-model="logo" />
                                    Sponsor Bersama &nbsp;
                                  </div>
                                  <div class="col-md-3">
                                    <input class="form-check-input mt-0" type="checkbox" value="Minor Sponsor"
                                      v-model="logo" />
                                    Minor Sponsor &nbsp;
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td style="width: 10%">Ukuran Logo</td>
                              <td>
                                <v-select placeholder="Pilih Ukuran Logo" :options="['Besar', 'Sedang', 'Kecil']"
                                  v-model="ukuran_logo_selected"></v-select>
                              </td>
                            </tr>
                            <tr>
                              <td style="width: 10%">Jumlah Logo</td>
                              <td>
                                <v-select :options="['Banyak', 'Sedikit', 'Sedang']" placeholder="Pilih Jumlah Logo"
                                  v-model="jumlah_logo_selected"></v-select>
                              </td>
                            </tr>
                            <tr>
                              <td style="width: 10%">
                                Benefit/Pemasaran Produk BRI
                              </td>
                              <td colspan="3">
                                <input class="form-check-input mt-0" type="radio" value="Ya" name="benefit"
                                  v-model="benefit" @change="pilihBenefit" />
                                Ya &nbsp;
                                <input class="form-check-input mt-0" type="radio" value="Tidak" name="benefit"
                                  v-model="benefit" @change="pilihBenefit" />
                                Tidak &nbsp;
                                <textarea v-if="showBenefit" v-model="informasi_benefit_pemasaran"
                                  class="form-control mt-1 mb-1" id="" cols="30" rows="10"></textarea>
                              </td>
                            </tr>
                            <tr>
                              <td style="width: 10%">
                                Pertimbangan Sponsor/Bantuan Dana
                              </td>
                              <td>
                                <textarea id="" cols="30" rows="10" class="form-control"
                                  v-model="pertimbangan_sponsor"></textarea>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </b-tab>

                    <!-- Keterangan Tab -->
                    <b-tab>
                      <template v-slot:title>
                        <span class="d-inline-block d-sm-none">
                          <i class="fas fa-cog"></i>
                        </span>
                        <span class="d-none d-sm-inline-block">Keterangan</span>
                      </template>
                      <div class="row p-2">
                        <table class="table mb-0 table-bordered table-condensed table-hover">
                          <thead>
                            <tr class="bg-light">
                              <th colspan="6">Keterangan</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td style="width: 10%">Tanggal Realiasi</td>
                              <td colspan="5">
                                <b-form-input type="date" v-model="tanggal_realiasi"></b-form-input>
                              </td>
                            </tr>
                            <tr>
                              <td style="width: 10%">
                                Nilai Sponsor Disetujui (Komitmen) <span class="text-danger">* Wajib diisi</span>
                              </td>
                              <td>
                                <input type="text" class="form-control" v-model="nilai_sponsor" v-on:input="
                                  nilai_sponsor = numberFormat(nilai_sponsor)
                                  " maxlength="15" />
                              </td>
                              <td style="width: 10%">Total Realisasi</td>
                              <td>
                                <input type="text" class="form-control" v-model="total_realiasi" v-on:input="
                                  total_realiasi = numberFormat(total_realiasi)" maxlength="15">
                              </td>
                              <td style="width: 10%">Sisa Anggaran</td>
                              <td>
                                <input type="text" class="form-control" v-model="sisa_anggaran" v-on:input="
                                  sisa_anggaran = numberFormat(sisa_anggaran)" maxlength="15">
                              </td>
                            </tr>
                            <tr>
                              <td style="width: 10%">
                                Nilai Sponsor Termasuk Pajak ?
                              </td>
                              <td colspan="5">
                                <div class="row">
                                  <div class="col-md-3">
                                    <input class="form-check-input mt-0" type="radio" value="Ya" name="nilai_sponsor"
                                      v-model="is_pajak" />
                                    Ya &nbsp;
                                  </div>
                                  <div class="col-md-3">
                                    <input class="form-check-input mt-0" type="radio" value="Tidak" name="nilai_sponsor"
                                      v-model="is_pajak" />
                                    Tidak &nbsp;
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td style="width: 10%">Termin Pembayaran</td>
                              <td colspan="5">
                                <v-select placeholder="-Pilih Termin-" :options="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
                                  v-model="termin_selected" @update:modelValue="pilihTerminPembayaran"></v-select>
                              </td>
                            </tr>
                            <tr>
                              <td style="width: 10%">Informasi Pembayaran</td>
                              <td colspan="5">
                                <div class="row">
                                  <div class="col-md-3">
                                    <input class="form-check-input mt-0" type="radio" value="Sudah Bayar"
                                      v-model="informasi_pembayaran" @change="pilihInformasiPembayaran" />
                                    Sudah Bayar &nbsp;
                                  </div>
                                  <div class="col-md-3">
                                    <input class="form-check-input mt-0" type="radio" value="Belum Bayar"
                                      v-model="informasi_pembayaran" @change="pilihInformasiPembayaran" />
                                    Belum Bayar &nbsp;
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr v-if="showRealisasiPembayaran == false"></tr>
                            <tr v-else v-for="(item, index) in realisasi_pembayaran" :key="index">
                              <td style="width: 10%">
                                Tanggal Realisasi (Termin {{ index + 1 }})
                              </td>
                              <td>
                                <input type="date" class="form-control" v-model="item.tanggal_bayar" />
                              </td>
                              <td style="width: 10%">
                                Nominal Transfer (Termin {{ index + 1 }})
                              </td>
                              <td>
                                <input type="text" class="form-control" v-model="item.nominal_bayar" v-on:input="
                                  item.nominal_bayar = numberFormat(
                                    item.nominal_bayar
                                  )
                                  " maxlength="15" />
                              </td>
                              <td style="width: 10%">
                                Bukti Transfer (Termin {{ index + 1 }})
                                <br /><small style="color: red">({{ textlabel_upload_tipe }}, Ukuran Maksimal
                                  Upload:
                                  {{ formatBytes(max_upload_size) }})</small>
                              </td>
                              <td>
                                <input type="file" :accept="label_upload_tipe" class="form-control"
                                  :id="'uploadFileDokumenBuktiTransfer' + index" @change="
                                    uploadFileDokumenBuktiTransfer(index)
                                    " />
                                <input type="hidden" v-model="item.bukti_bayar" />
                                <div class="respond-input-file float-left" :id="'uploadLoadingDokumenBuktiTransfer' + index
                                  "></div>
                              </td>
                            </tr>
                            <tr>
                              <td style="width: 10%">Sharing Dana</td>
                              <td colspan="5">
                                <div class="row">
                                  <div class="col-md-3">
                                    <input class="form-check-input mt-0" type="radio" value="Ya" v-model="sharing_dana"
                                      @change="pilihSharingDana" />
                                    Ya &nbsp;
                                  </div>
                                  <div class="col-md-3">
                                    <input class="form-check-input mt-0" type="radio" value="Tidak" v-model="sharing_dana"
                                      @change="pilihSharingDana" />
                                    Tidak &nbsp;
                                  </div>
                                </div>
                                <div class="row">
                                  <table v-if="showSharingDana"
                                    class="table mb-0 table-bordered table-condensed table-hover w-full mb-2 mt-2">
                                    <thead>
                                      <tr class="bg-light text-center">
                                        <th>Kantor Wilayah/Divisi</th>
                                        <th>Presentase</th>
                                        <th>Nominal</th>
                                        <th>
                                          <div class="btn btn-success btn-sm" v-on:click="addsharingdana()">
                                            <i class="bx bx-plus"></i>
                                          </div>
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr v-if="sharing_dana_array.length == 0">
                                        <td colspan="2">TIdak Ada Data</td>
                                      </tr>
                                      <tr v-for="(
                                          item, index
                                        ) in sharing_dana_array" :key="index">
                                        <td>
                                          <input type="text" class="form-control" v-model="item.kantor" />
                                        </td>
                                        <td>
                                          <input type="number" class="form-control" v-model="item.presentase" />
                                        </td>
                                        <td>
                                          <input type="text" class="form-control" v-model="item.nominal" v-on:input="
                                            item.nominal = numberFormat(
                                              item.nominal
                                            )
                                            " />
                                        </td>
                                        <td class="text-center">
                                          <button type="button" class="btn btn-danger btn-sm"
                                            @click="removesharingdana(index)">
                                            <i class="fa fa-minus"></i>
                                          </button>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td style="width: 10%">Kegiatan Terlaksana?</td>
                              <td colspan="5">
                                <div class="row">
                                  <div class="col-md-3">
                                    <input class="form-check-input mt-0" type="radio" value="Ya"
                                      v-model="is_terlaksana" />
                                    Ya &nbsp;
                                  </div>
                                  <div class="col-md-3">
                                    <input class="form-check-input mt-0" type="radio" value="Tidak"
                                      v-model="is_terlaksana" />
                                    Tidak &nbsp;
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td style="width: 10%">Catatan Kegiatan</td>
                              <td colspan="5">
                                <textarea v-model="catatan_kegiatan" id="" cols="30" rows="10"
                                  class="form-control"></textarea>
                                <p class="text-danger">
                                  Catatan Kegiatan, diisi apabila: 1. Kegiatan
                                  tidak terlaksana. 2. Kegiatan terdapat hasil
                                  memuaskan
                                </p>
                              </td>
                            </tr>
                            <tr>
                              <td style="width: 10%">PIC Pelaksana</td>
                              <td colspan="5">
                                <b-form-input type="text" v-model="pic_terlaksana"></b-form-input>
                              </td>
                            </tr>
                            <tr>
                              <td style="width: 10%">No Kontak Pelaksana</td>
                              <td colspan="5">
                                <input type="text" v-model="kontak_terlaksana" class="form-control"
                                  onkeypress='return event.charCode >= 48 && event.charCode <= 57'>
                              </td>
                            </tr>
                            <tr>
                              <td style="width: 10%">Alamat Pelaksana</td>
                              <td colspan="5">
                                <b-form-input type="text" v-model="alamat_terlaksana"></b-form-input>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="col-md-12 text-end">
                        <b-button type="submit" variant="primary" class="m-1"><i class="fa fa-save"></i> SIMPAN
                          SPONSORSHIP</b-button>
                      </div>
                    </b-tab>
                  </b-tabs>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <div class="row">
                <div class="col-md-6">
                  <router-link :to="{ name: 'sponsorship' }" class="btn bg-secondary text-white">
                    <i class="fa fa-chevron-left"></i> Kembali Ke List
                    Sponsorship
                  </router-link>
                </div>
              </div>
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </Layout>
</template>
